
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal_content_common {
    display: grid;
    grid-template-columns: auto 24px;
    gap: 10px;
    width: 514px;
  }

  .modal-content {
    display: block;
    background-color: #1E7040;
    color: white;
    padding: 20px;
    border-radius: 30px;
    position: relative;
    width: 480px;
    max-width: 90%;
  }

  .modal_content_scroll {
    display: block;
    overflow-y: auto;
    max-height: 602px;
  }
  
  .modal-close {
    /*position: absolute;*/
    color: white;
    /*top: 10px;
    right: -40px;*/
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    align-self: start;
  }

  .selector_mode {
    display: inline-flex;
    width: 100%;
    padding: 20px;
  }

  .selector_mode_item {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #FFFFFF80;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
  }

  .selector_mode_item_selected {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #E47E1A;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 400;
  }

  .autorization_fields_block {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .autorization_input_fields {
    display: block;
    margin-left: 20px;
    margin-bottom: 40px;
    margin-top: 10px;
    width: 405px;
    height: 40px;
    border-radius: 30px;
    color: black;
    border: none;
    padding-left: 15px;
  }

  .autorization_entry_button {
    background-color: #E47E1A;
    color: white;
    width: 420px;
    height: 40px;
    border-radius: 30px;
    border: none;
    margin-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .autorization_entry_button:disabled {
    opacity: 0.5;                /* Делает кнопку блеклой */
    cursor: not-allowed;
  }

  .selector_recover_password{
    margin-top: 20px;
    padding-top: 20px;
    color: #FFFFFF80;
    border-top: 1px solid white;
    text-align: center;
    cursor: pointer;
  }

  .autorization_label_fld {
    font-size: 15px;
    font-weight: 400;
    color: white;
    margin-left: 20px;
  }

  .autorization_label_fld_button {
    font-size: 15px;
    font-weight: 400;
    color: white;
    margin-left: 20px;
    cursor: pointer;
  }

  .login_status_message {
    text-align: center;
    margin-bottom: 10px;
  }

  .completed {
    color: white;
    margin-left: 15px;
  }

  .not_completed {
    color: red;
    margin-left: 15px;
  }

  .acception {
    display: grid;
    grid-template-columns: 25px auto;
    gap: 5px;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .acception_text {
    font-size: 14px;
    align-self: start;
  }

  .check_field {
    align-self: start;
    width: 20px;
    height: 20px;
    background-color: red;
  }

  .loader {
    border: 2px solid #f3f3f3; /* Светлый цвет */
    border-top: 2px solid #3498db; /* Цвет индикатора */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }


  .custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .custom-checkbox input[type="checkbox"] {
    display: none; /* Скрываем стандартный чекбокс */
  }
  
  .custom-checkbox .checkmark {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    background-color: white; /* Цвет фона при невыбранном чекбоксе */
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  /* Изменяем цвет фона и границы, когда чекбокс выбран */
  .custom-checkbox input[type="checkbox"]:checked + .checkmark {
    background-color: #1E7040; /* Цвет фона при выбранном чекбоксе */
    border-color: white;
  }
  
  /* Галочка */
  .custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Отображаем галочку при выбранном чекбоксе */
  .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
    display: block;
  }
  